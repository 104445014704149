@import "~react-datepicker/dist/react-datepicker.css";
@import "~rc-time-picker/assets/index.css";

.form {
  display: flex;
  flex-wrap: wrap;

  .form__tab-header {
    position: absolute;
    right: 20px !important;
    top: -12px;
    height: 10px;
  }
  .form__tab-select {
    border-top: 3px solid #00bcd4;
    border-bottom: 2px solid rgb(252, 252, 252) !important;
    border-left: 1px solid #d4d5d8;
    border-right: 1px solid #d4d5d8;
  }
  .form__tab-description {
    position: absolute;
    right: 20px !important;
    top: -14px;
    // height: 20px;
  }
  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed("colorFieldsBorder");
      color: themed("colorText");
    }

    &[disabled] {
      @include themify($themes) {
        background: themed("colorBackgroundBody");
      }

      &:focus,
      &:active {
        @include themify($themes) {
          border-color: themed("colorBorder");
        }
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {
      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description,
      .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form__form-group--address {
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;

  @include themify($themes) {
    color: themed("colorText");
  }

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    background: themed("colorFieldsBorder");
    border: 1px solid themed("colorFieldsBorder");
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-file {
  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed("colorFieldsBorder");
      color: themed("colorText");
    }

    &:hover {
      @include themify($themes) {
        background: themed("colorFieldsBorder");
      }
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;

  @include themify($themes) {
    background: themed("colorFieldsBorder");
    border: 1px solid themed("colorFieldsBorder");
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}
.form__form-group-activity {
  height: 140px;
  border: 5px solid black;
}
// .form__multiselect-close {
//   position: relative;
//   width: 10px;
//   height: 10px;
//   top: -6px;
//   padding-left: 5px;
//   cursor: pointer;
//   color: rgb(0, 68, 255);
// }

// .form__multiselect-container {
//   position: relative;
//   display: inline-block;
//   width: 14rem;
//   height: 5rem;
//   padding-bottom: 10px;
// }
// .form__multiselect-item {
//   background-color: white;
//   color: rgb(0, 68, 255);
//   border-radius: 12px;
//   padding: 6px 8px;
//   margin-right: 4px;
//   // margin-bottom: 4px;
//   margin-top: 4px;
//   font-size: 10px;
//   font-weight: 500;
//   line-height: 1;
//   text-align: center;
//   white-space: nowrap;
//   vertical-align: middle;
//   cursor: pointer;
//   display: inline-block;
//   height: 30px;
//   border: 3px solid rgb(158, 255, 47);
//   transition: all 0.2s ease-out;
//   &:hover {
//     color: white;
//     background-color: rgb(0, 68, 255);
//   }
// }
.form__form-group-input-wrap {
  width: 100%;
 // height: 10vh;
}

.form__form-group-error {
  font-size: 13px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 30%;
  margin-bottom: 15px;
  position: relative;
}

.input-container-ld {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 30%;
  margin-bottom: 15px;
  position: relative;
}

.icon {
  padding: 10px;
  background: #5de3be;
  color: white;
  min-width: 50px;
  text-align: center;
  margin-right: 0px;
  font-size: 22px;
  line-height: 13px;
  font-weight: 900;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 0;
  }
}

.icon-ld {
  padding: 10px;
  background: #5dbde3;
  color: white;
  min-width: 50px;
  text-align: center;
  margin-right: 0px;
  font-size: 22px;
  line-height: 13px;
  font-weight: 900;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 0;
  }
}

.icon-close {
  position: absolute;
  right: 10px;
  top: 15px;
  background: transparent;
  border: aliceblue;
  span {
    font-size: 0;
  }
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  background-color: $white;
  border: 1px solid #5de3be;
  margin-top: 0;
}

.input-field-ld {
  width: 100%;
  padding: 10px;
  outline: none;
  background-color: $white;
  border: 1px solid #5dbde3;
  margin-top: 0;
}

@media screen and (max-width: 786px) {
  // flex-wrap: wrap;

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
  }
  .icon-close {
    position: absolute;
    right: 10px;
    top: 15px;
    background: transparent;
    border: aliceblue;
    span {
      font-size: 0;
    }
  }
}

.unique-bar-codes-group-field {
  width: 100%;
}

.unique-bar-codes-group-field div.CSVImporter_Importer {
  width: 100%;
  font-size: 15px;
}