.badgeModule {
	//default

	padding-top: 80px;
	padding-left: 20px;
	padding-right: 20px;
	overflow-x: hidden;
	background-color: #f2f4f7;
	transition: all 0.2s ease-out;
	@media screen and (min-width: 440px) {
		margin-left: 50px;

		&.sidebar-visible {
			margin-left: 250px;
		}
	}

	.badgeModule-link {
		padding-right: 10px;
	}
}

.badgeModule-picker-dropdown-toggle {
	height: initial;
	padding: 0.5rem 1rem;
}