.not-found {
	text-align: center;
	height: 100vh;
	overflow: auto;
	display: flex;
	background: url(../../shared/img/404/bg_404.png) no-repeat center;
	background-size: cover;

	button {
		margin: 0;
	}
}

.not-found__content {
	margin: auto;
	padding: 10px;
}

.not-found .not-found__info {
	color: #ffffff;
	margin-bottom: 20px;
	margin-top: 90px;
}

.not-found__image {
	max-width: 500px;
	width: 100%;
}
